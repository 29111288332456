.Toastify__toast-container {
  width: 400px;
}

.Toastify__toast-container--bottom-right {
  right: 2rem;
  bottom: 8rem;
}

.Toastify__toast-container--top-center {
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.Toastify__toast {
  padding: 0;
  border-radius: 8px;
}

.Toastify__toast-body {
  padding: 0;
}

.Toastify__toast--error,
.Toastify__toast--success,
.Toastify__toast--info,
.processingBox {
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(24px);
}

.Toastify__toast--error {
  background: rgba(250, 83, 65, 0.2);
}

.Toastify__toast--success {
  background: rgba(10, 213, 189, 0.2);
}

.Toastify__toast--info {
  background: rgba(125, 125, 125, 0.2);
}

.processingBox {
  background: rgba(125, 125, 125, 0.2);
}

.Toastify__progress-bar--error {
  background: #fa5542;
}

.Toastify__progress-bar--success {
  background: #0ad5bd;
}

.transactionToast {
  max-width: 100%;
  line-height: 20px;
  padding: 16px;

  .info {
    padding-bottom: 24px;
    border-bottom: 1px solid #5d5d5d;
  }
}

.toastHeader {
  padding: 0 12px;
  height: 40px;
  background: rgba(0, 0, 0, 0.2);
}

.toastInfo {
  padding: 8px 10px 20px;

  .iconBg {
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
}