* {
  outline: none;
  // -ms-overflow-style: none !important;
  // /* IE and Edge */
  // scrollbar-width: none !important;

  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none !important;
  // }
}

// div,
// span,
// p {
//   caret-color: transparent;
// }

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-size: 14px;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, #25005a 0%, #111555 100%) !important;
}

// body::-webkit-scrollbar {
//   width: 4px;
//   background: #0a0a0a
// }

// body::-webkit-scrollbar-track {
//   background: 0 0
// }

// body::-webkit-scrollbar-thumb {
//   background: #E4700E;
//   border-radius: 16px;
// }

// body::-webkit-scrollbar-thumb:hover {
//   background: #E4700E;
// }

.hover {
  cursor: pointer;
}

#root {
  position: relative;
}

.app {
  color: #ffffff;
  font-family: "Poppins";
}

.font-family-DMSans {
  font-family: "DM Sans";
}

.font-family-Poppins {
  font-family: "Poppins";
}

.MuiButtonBase-root {
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0 30px;
}

.container2 {
  width: 1380px;
  max-width: 100%;
}

.app-container {
  width: 100vw;
  height: 100vh;
  background-image: url(./assets/images/bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: scroll;
}

.esbt-container {
  width: 100vw;
  height: 100vh;
  background: url(./assets/images/bg_esbt.svg);
  background-color: #5a0fa6;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;
}

.flex-1 {
  flex: 1;
}

.padding-16 {
  padding: 16px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-32 {
  margin-left: 32px;
}

.ml-36 {
  margin-left: 36px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-44 {
  margin-left: 44px;
}

.ml-48 {
  margin-left: 48px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-26 {
  margin-top: 26px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-38 {
  margin-top: 38px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-44 {
  margin-top: 44px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-64 {
  margin-top: 64px;
}

.font-10 {
  font-size: 10px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
  line-height: 24px;
}

.font-18 {
  font-size: 18px;
  line-height: 28px;
}

.font-20 {
  font-size: 20px;
  line-height: 30px;
}

.font-24 {
  font-size: 24px;
  line-height: 36px;
}

.font-32 {
  font-size: 32px;
}

.font-30 {
  font-size: 30px;
  line-height: 45px;
}

.font-36 {
  font-size: 36px;
  line-height: 54px;
}

.font-40 {
  font-size: 40px;
}

.font-48 {
  font-size: 48px;
  line-height: 72px;
}

.font-64 {
  font-size: 64px;
  line-height: 96px;
}

.font-weight-b {
  // font-weight: bold;
  // font-variation-settings: "wght" 700;
  font-family: "Poppins-ExtraBold";
}

.font-weight-6 {
  // font-weight: 600;
  // font-variation-settings: "wght" 600;
  font-family: "Poppins-Bold";
}

.font-weight-5 {
  // font-weight: 500;
  // font-variation-settings: "wght" 500;
  font-family: "Poppins-Medium";
}

.font-weight-3 {
  // font-weight: 300;
  // font-variation-settings: "wght" 300;
  font-family: "Poppins-Light";
}

.font-weight-4 {
  font-family: "Poppins";
}

.color1 {
  color: #ffffff;
}

.color2 {
  color: #9b96ff;
}

.color3 {
  color: rgb(255, 255, 255, 0.25);
}

.color4 {
  color: #eef2f9;
}

.color5 {
  color: #8f92a1;
}

.color6 {
  color: #6b75a5;
}

.color7 {
  color: #f89542;
}

.color8 {
  color: rgb(255, 255, 255, 0.5);
}

.color9 {
  color: rgba(238, 242, 249, 0.5);
}

.color10 {
  color: #a4fc5d;
}

.color11 {
  color: #04143d;
}

.color12 {
  color: #724a01;
}

.color13 {
  color: #593507;
}

.color14 {
  color: rgba(255, 255, 255, 0.54);
}

.color15 {
  color: #faff00;
}

.color16 {
  color: #69d0c6;
}

.color17 {
  color: rgba(255, 255, 255, 0.7);
}

.color18 {
  color: #0ad5bd;
}

.color19 {
  color: #ffd100;
}

.color20 {
  color: rgba(238, 242, 249, 0.75);
}

.color21 {
  color: rgba(238, 242, 249, 0.16);
}

.color22 {
  color: rgba(238, 242, 249, 0.7);
}

.color23 {
  color: rgba(238, 242, 249, 0.3);
}

.color24 {
  color: #fa5341;
}

.color25 {
  color: #dfe8f1;
}

.color26 {
  color: rgba(238, 242, 249, 0.9);
}

.color27 {
  color: #00a9ff;
}

.line {
  width: 8px;
  height: 8px;
  background: #5e58db;
}

.line2 {
  width: 4px;
  height: 22px;
  background: #2f2d6c;
}

.line3 {
  width: 4px;
  height: 22px;
  background: #5e58db;
}

.b-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.t-border {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.toolBottom {
  border-bottom: 1px dashed rgba(238, 242, 249, 0.5);
  cursor: help;
}

.bg1 {
  background: #20253e;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-b {
  border-bottom: 1px solid #2f2d6c;
}

.underline {
  text-decoration-line: underline;
}

a {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.MuiLink-root.active {
  text-decoration: none !important;
}

img {
  max-width: 100%;
  cursor: pointer;
}

.word-break {
  word-break: break-word;
}

.jYxAGf {
  z-index: 1000;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.fit-content {
  width: fit-content;
}

.max {
  width: 50px;
  height: 22px;
  background: rgba(248, 149, 66, 0.1);
  border-radius: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 680px) {
  .padding-16 {
    padding: 12px;
  }
}
